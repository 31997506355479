import { SalaryTable } from '@/interfaces/salaryTable';
import {IList} from '@/utilities/lists';

export interface CaoComplianceDetail {
  id: string;
  cao_id: string;
  cao_text: string;
  duration_end: string;
  duration_start: string;
  readable_name_year: string;
  vacation_time_type: 'days_per_year' | 'hours_per_year' | '';
  vacation_time_amount: number;
  vacation_allowance: number;
  timesheet_overtime_interaction: string;
  year_end_bonus_type: string;
  year_end_bonus_amount: number;
  one_off_bonuses: Array<{
    bonus_date: string, description: string, bonus_type: 'percentage' | 'amount', amount: number,
  }>;
}

export interface Inlener {
  inlener_id: number;
  type: InlenerType;
  contact: string;
  company_name?: string;
  kvk_nr?: string;
  invited_by?: string;
  status: string;
  updated_at: string;
  favorite: boolean;
}

export interface ToggleInlenerFavorite {
  inlener_id: number;
  type: InlenerType;
}

export enum InlenerType {
  SUBSCRIPTION = 'subscription',
  INVITE = 'invite',
}

export interface InlenerCao {
  id: number;
  full_name: string;
  duration_start: string;
  duration_end: string;
  cao_text: string;
  based_on: string;
  api: boolean;
  tool: boolean;
  updated_at: string;
  check_by_caoloon_requested: boolean;
  checked_by_caoloon: boolean;
  in_agreement: boolean;
  signed_fullname: string;
  guid: string;
  version: number;
}

export interface CaoCompliance {
  id: number;
  cao_id: string;
  cao_name: string;
  signed_fullname: string;
  kvk_inlener: number;
  api: boolean;
  in_agreement: boolean;
  created_at: string;
}

export interface InlenerWerksoort {
  name: string;
  type: string;
  amount: number;
  description: string;
  comment?: string;
  start_date: string | null;
  end_date?: string;
}

export interface InlenerCaoDetail {
  company_name: string;
  full_name: string;
  cao_text: string;
  kvk_inlener: string;
  duration_end: string;
  based_on: string;
  updated_at: string;
  werksoorten: InlenerWerksoort[];
  duration_start: string;
  signed_fullname: string;
  holding_kvk_inlener: string | undefined;
  year_end_bonus_type?: string;
  year_end_bonus_amount?: number;
  year_end_bonus_comment?: string;
  timesheet_overtime_interaction: string;
  one_off_bonuses: Array<{
    bonus_date: string, description: string, bonus_type: 'percentage' | 'amount', amount: number,
  }>;
  educations: Array<{ description: string, inleners_jobs: string[] }>;
}

export type InlenerCaoListAction = (filters: {
  page: number,
  contains?: string,
  only_user?: boolean,
  inlener_subscription_id?: number,
}) =>
  Promise<IList<InlenerCao>>;

export type CaoComplianceListAction = (filters: {
  page: number,
  contains?: string,
  only_user?: boolean,
  inlener_subscription_id?: number,
}) =>
  Promise<IList<CaoCompliance>>;

export type ToggleInlenerCaoAction = (params: { inlener_cao_id: number, api: boolean, tool: boolean }) => Promise<{}>;

export type ToggleCaoComplianceAction = (params: { cao_compliance_id: number, api: boolean }) => Promise<{}>;

export type CheckInlenerCaoAction = (params: { inlener_cao_id: string }) => Promise<{}>;

export type InlenerCaoDetailAction = (inlenerCaoId: number) => Promise<InlenerCaoDetail>;

export type CaoComplianceDetailAction = (caoId: string) => Promise<CaoComplianceDetail>;

export type WerksoortsAction = (caoId: string) => Promise<InlenerWerksoort[]>;

export type SalaryTableAction = (caoId: string) => Promise<SalaryTable[]>;

export type InlenerCaoVersionsAction = (inlenerCaoGuid: string) => Promise<InlenerCao[]>;

export type InviteInlenerAction = (payload: { email: string }) =>
  Promise<IList<Inlener>>;
export type InlenerListAction = (payload: { page: number }) =>
  Promise<IList<Inlener>>;
export type InlenerDetailsAction = (subscription_id: string) =>
  Promise<InlenerDetails>;
export type ToggleFavoriteInlener = (inlenerData: ToggleInlenerFavorite) =>
  Promise<{ favorite: boolean }>;

export interface InlenerDetails {
  company_name: string;
  accounts: string[];
  invited_by: string;
  updated_at: string;
  kvk_nr: string;
  favorite: boolean;
  invite_email: string;
}
